import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import { _ } from "vue-underscore";
import store from "@/store/index.js";

export default {
  data: () => ({
    DeletedLeadList: [],
    searchText: "",
    showLoader: false,
    selectedFile: [],
    selected: "",
    selectedId: 0,
    selectedRows: [],
    callQueuePresence:
      parseInt(localStorage.getItem("call_queue_presence")) || 0,
  }),
  mixins: [paginationMixin],
  components: { "header-list": headerList },
  computed: {
    user() {
      return this.$store.state.userDetail;
    },
    hasLeadsRecoverPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-recover";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasLeadsDeletePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-permanent-delete";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
  },
  methods: {
    updateQueuePresence() {
      const newValue = this.callQueuePresence === 1 ? 0 : 1;
      this.axios
        .get("/update-queue-presence", {
          params: {
            presence: newValue,
          },
        })
        .then((response) => {
          this.callQueuePresence = newValue;
          localStorage.setItem("call_queue_presence", newValue);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // formatDateTime(timestamp) {
    //   const date = new Date(timestamp);
    //   const months = [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ];

    //   const month = months[date.getMonth()];
    //   const day = date.getDate().toString().padStart(2, "0");
    //   const year = date.getFullYear().toString().slice(2);
    //   const time = timestamp.slice(11, 16);

    //   return `${month}/${day}/${year} ${time}`;
    // },

    getNextPage(page) {
      this.$refs.paginate.selected = page;
      this.paginate.current_page = page;
      this.getAll();
    },
    search() {
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.$store.state.recordTake;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },

    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      this.axios
        .get("/deleted-leads-list")
        .then(function (response) {
          _vm.showLoader = false;
          _vm.DeletedLeadList = JSON.parse(atob(response.data.data));
        })
        .catch(function (error) {});
    },
    recoverSales(id) {
      let _vm = this;
      let _msg = `Are you sure you want to Recover this lead? <br>` + id;
      this.$dialog
        .confirm(_msg)
        .then(() => {
          store.state.isLoaderShow = true;
          _vm.axios.get("/lead-recover/" + id).then(function (response) {
            _vm.getAll();
            store.state.isLoaderShow = false;
          });
        })
        .catch(function () {});
    },
    deleteSales(id) {
      let _vm = this;
      let _msg = `Are you sure you want to Permanent Delete this lead? ` + id;
      this.$dialog
        .confirm(_msg)
        .then(() => {
          store.state.isLoaderShow = true;
          _vm.axios
            .delete("/lead-permanent-delete/" + id)
            .then(function (response) {
              _vm.getAll();
              store.state.isLoaderShow = false;
            });
        })
        .catch(function () {});
    },
  },
  mounted() {
    this.getAll();
    this.callQueuePresence =
      parseInt(localStorage.getItem("call_queue_presence")) || 0;
  },
};
